// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import boothBgImg from "./assets/booth-bg.jpg"
import boothManImg from "./assets/booth-man.png"
import womanImg from "./assets/woman.png"
import handImg from "./assets/hand.png"
import manImg from "./assets/man.png"
import carParkTextImg from "./assets/car-park-text.png"
import communalParkTextImg from "./assets/communal-park-text.png"
import balloonsImg from "./assets/balloons.png"

// Sprites
import birdImg from "./assets/sprites/bird.png"
import confettiImg from "./assets/sprites/confetti.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 1,
  preHeading: "Powerful Communities",
  heading: "It's here that we gather and where good gets done.",
  content:
    "This is where we greet our neighbours. Where we walk together hand in hand. Where we learn and play and always stop for a natter. It’s where we meander for our messages – buying bread from the bakery and grabbing our greens at the grocers. This is where we convene in caring, connecting and celebrating. Where we give time and share power. It’s where we create a shared place for our community.",
  slug: "powerful-communities",
  nextSlug: "living-locally",
}

const PowerfulCommunities = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 1159 },
    }),
    boothBg = useRef({
      parallax: 0.5,
      src: boothBgImg,
      el: null,
      offset: { x: 2966, y: 916 },
    }),
    boothMan = useRef({
      parallax: 0.5,
      src: boothManImg,
      el: null,
      offset: { x: 2985, y: 1420 },
    }),
    woman = useRef({
      parallax: 0.6,
      src: womanImg,
      el: null,
      offset: { x: 1492, y: 910 },
    }),
    hand = useRef({
      parallax: 0.6,
      src: handImg,
      el: null,
      offset: { x: 1717, y: 1151, r: 5 },
      rotationPoint: { x: 190, y: 148 },
    }),
    man = useRef({
      parallax: 0.6,
      src: manImg,
      el: null,
      offset: { x: 1970, y: 860 },
    }),
    carParkText = useRef({
      parallax: 0.5,
      src: carParkTextImg,
      el: null,
      offset: { x: 1763, y: 734 },
    }),
    communalParkText = useRef({
      parallax: 0.5,
      src: communalParkTextImg,
      el: null,
      offset: { x: 1439, y: 728 },
      hidden: 1,
    }),
    bird = useRef({
      parallax: 0.5,
      src: birdImg,
      el: null,
      offset: { x: 1336, y: 419 },
      size: { w: 260, h: 250 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    confetti1 = useRef({
      parallax: 4,
      src: confettiImg,
      el: null,
      offset: { x: 850, y: 0 },
      size: { w: 1140, h: 1170 },
      currentSprite: 1,
      rowCount: 3,
      spriteFrames: 18,
      slowFactor: 4,
      loop: false,
      paused: true,
    }),
    confetti2 = useRef({
      parallax: 4,
      src: confettiImg,
      el: null,
      offset: { x: 2100, y: 0 },
      size: { w: 1140, h: 1170 },
      currentSprite: 1,
      rowCount: 3,
      spriteFrames: 18,
      slowFactor: 4,
      loop: false,
      paused: true,
    }),
    confetti3 = useRef({
      parallax: 4,
      src: confettiImg,
      el: null,
      offset: { x: 250, y: 0 },
      size: { w: 1140, h: 1170 },
      currentSprite: 1,
      rowCount: 3,
      spriteFrames: 18,
      slowFactor: 4,
      loop: false,
      paused: true,
    }),
    confetti4 = useRef({
      parallax: 4,
      src: confettiImg,
      el: null,
      offset: { x: 2700, y: 0 },
      size: { w: 1140, h: 1170 },
      currentSprite: 1,
      rowCount: 3,
      spriteFrames: 18,
      slowFactor: 4,
      loop: false,
      paused: true,
    }),
    balloons = useRef({
      parallax: 1.1,
      src: balloonsImg,
      el: null,
      offset: { x: -80, y: 1200 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    boothBg.current,
    boothMan.current,
    // boothFg.current,
    mg.current,
    woman.current,
    hand.current,
    man.current,
    carParkText.current,
    communalParkText.current,
    bird.current,
    confetti1.current,
    confetti2.current,
    confetti3.current,
    confetti4.current,
    fg.current,
    balloons.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    bird.current,
    confetti1.current,
    confetti2.current,
    confetti3.current,
    confetti4.current,
  ])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 1506,
    y: 499,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  // Triggered animations
  const showBoothMan = direction => {
    if (direction === "in") {
      gsap.to(boothMan.current.offset, {
        y: 944,
        duration: 1,
        ease: "power3.out",
      })
    }
  }

  // Timed animations
  const pushButton = () => {
    let flashSignOut, flashSignIn

    gsap.to(hand.current.offset, {
      r: -5,
      duration: 0.8,
      ease: "power3.inOut",
      onComplete: () => {
        confetti1.current.paused = false

        flashSignOut = setInterval(() => {
          carParkText.current.hidden = !carParkText.current.hidden
        }, 100)

        setTimeout(() => {
          clearInterval(flashSignOut)
          carParkText.current.hidden = true
          flashSignIn = setInterval(() => {
            communalParkText.current.hidden = !communalParkText.current.hidden
          }, 100)

          setTimeout(() => {
            clearInterval(flashSignIn)
            communalParkText.current.hidden = false
          }, 500)
        }, 500)
      },
    })
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      if (isMobile) showBoothMan("in")
    }, 1000)
    let pushButtonTimeout = setTimeout(pushButton, 7000)
    let confetti2Timeout = setTimeout(() => {
      confetti2.current.paused = false
    }, 8000)
    let confetti3Timeout = setTimeout(() => {
      confetti3Timeout = setInterval(() => {
        confetti3.current.currentSprite = 0
        confetti3.current.paused = false
      }, 2000)
    }, 6200)
    let confetti4Timeout = setTimeout(() => {
      confetti4Timeout = setInterval(() => {
        confetti4.current.currentSprite = 0
        confetti4.current.paused = false
      }, 2000)
    }, 6400)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
      if (pushButtonTimeout) clearTimeout(pushButtonTimeout)
      if (confetti2Timeout) clearTimeout(confetti2Timeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showBoothMan,
              x: 2900,
              y: 600,
              w: 337,
              h: 840,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default PowerfulCommunities
